.ant-tree-list {
  margin-top: 8px;
}

.ant-tree-title {
  user-select: none;
}

.ant-tree {
  width: 300px;
}

@media (min-width: 1920px) {
  .ant-tree {
    width: 622px;
  }
}

@media (max-width: 320px) {
  .ant-tree {
    width: 260px;
  }
}

.ant-tree-checkbox-inner,
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  transition: none;
}

.ant-tree-checkbox-checked::after {
  border-color: #de2027;
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner,
.ant-tree-checkbox-checked::after {
  border-color: #de2027;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: #de2027;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #ffffff;
  border-color: #de2027;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #de2027;
}
